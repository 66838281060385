<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item @click="$router.go(-1)"
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        $route.query.name
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="tabs">
      <div
        v-show="tabMenu.length > 0"
        class="tabItem"
        :class="{ active: item.code === tabCode }"
        v-for="(item, index) in tabMenu"
        :key="index"
      >
        <span @click="tabChange(item)">
          {{ item.name }}
        </span>
        <div class="triangle" v-if="item.code === tabCode"></div>
      </div>
    </div>
    <div class="navbar">
      <ul>
        <li
          v-for="item in Erlist"
          :key="item.code"
          :class="{ selColor: item.code === selindex }"
          @click="taberChange(item.code)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="contentList">
      <div class="contentItem" v-for="(item, index) in list" :key="index">
        <img :src="item.pcPhoto" alt="" />
        <div class="content">
          <div class="left">
            <p class="name" @click="routerPush(item)">{{ item.name }}</p>

             <!-- 线下培训时间 -->
            <p
              class="teacherName"
              v-if="item.openStartTime && item.openEndTime"
            >
              <img
                width="11"
                height="14"
                src="@/assets/image/index/roup980.png"
                alt=""
              />
              {{ item.openStartTime.substring(0,11) }} - {{ item.openEndTime.substring(0,11) }}
            </p>
            <!-- 线下培训地点 -->
            <p class="teacherName" v-if="item.province&&item.city">
              <img
                width="11"
                height="14"
                src="@/assets/image/index/roup979.png"
                alt=""
              />
              {{ item.province }} {{ item.city }} | {{ item.district }}
            </p>
            <div
              class="navs"
              v-else-if=" item.type == 1 ||item.type == 4 ||(item.couponPrice && item.couponList.length !== 0)"
            >
              <span class="type" v-if="item.type == 1"
                >{{ item.courseCategory }} | {{ item.courseHour }}</span
              >
              <span class="type" v-if="item.type == 4">{{
                item.answerNumber
              }}</span>
              <span
                class="nav"
                v-show="item.couponPrice"
                v-for="(ite, ind) in item.couponList"
                :key="ind"
                >{{ ite }}</span
              >
            </div>
            <p class="teacherName" v-if="item.type == 1 && !item.openStartTime && !item.openEndTime">
              主讲老师：{{
                item.teacherList ? item.teacherList.join(", ") : "-"
              }}
            </p>
            <p
              class="slogan onlyOneHidden"
              v-if="item.slogan"
              v-html="item.slogan"
            ></p>
            <p
              class="introduce mallHidden"
              v-if="
                (item.type == 4 && item.pcDetail) ||
                (item.type != 4 && item.abstracts)
              "
            >
              <span class="backGround" v-if="item.type == 2 || item.type == 3"
                >Editor's Pick</span
              >
              {{ item.type == 4 ? font(item.pcDetail) : font(item.abstracts) }}
            </p>
          </div>
          <div class="right">
            <div class="price">
              <p class="new">
                <span v-if="item.couponPrice"
                  ><span class="new_point">￥</span
                  >{{ managePrice(item.couponPrice)[0]
                  }}<span class="new_point"
                    >.{{ managePrice(item.couponPrice)[1] }}</span
                  ></span
                >
                <span class="free" v-else>免费</span>
              </p>
              <p class="old" v-if="item.original && item.couponPrice">
                <!-- {{item.original}} -->
                ￥{{ item.originalPrice.toFixed(2) }}
              </p>
            </div>
            <!-- @click.stop="visible = true,fontText = '系统升级，请下载华夏云课堂app进行购买'" -->

            <!-- 四种跳转 -->
            <div
              class="button2"
              v-if="item.type == 2 || item.type == 3"
              @click="
                $router.push(
                  '/knowledgeMall/curriculumDetail?productId=' +
                    $AES.encode_data(item.productId + '') +
                    '&name=' +
                    $route.query.name
                )
              "
            >
              <!-- <p>{{ item.couponPrice ? "立即购买" : "加入学习" }}</p> -->
              查看详情
              <img src="@/assets/image/Group 5446.png" alt="" />
            </div>
            <div
              class="button2"
              v-else-if="item.type == 1"
              @click="
                $router.push(
                  '/knowledgeMall/courseDetail?productId=' +
                    $AES.encode_data(item.productId + '') +
                    '&code=' +
                    item.code +
                    '&name=' +
                    $route.query.name
                )
              "
            >
              <!-- <p>{{ item.couponPrice ? "立即购买" : "加入学习" }}</p> -->
              查看详情
              <img src="@/assets/image/Group 5446.png" alt="" />
            </div>
            <div
              class="button2"
              v-else-if="item.type == 4"
              @click="
                $router.push(
                  '/knowledgeMall/examDetail?productId=' +
                    $AES.encode_data(item.productId + '') +
                    '&name=' +
                    $route.query.name
                )
              "
            >
              <!-- <p>{{ item.couponPrice ? "立即购买" : "加入学习" }}</p> -->
              查看详情
              <img src="@/assets/image/Group 5446.png" alt="" />
            </div>
            <!-- <div class="button2">查看详情</div> -->
          </div>
        </div>
      </div>
      <a-spin class="spin" v-show="loading" />
      <a-pagination
        v-show="total"
        :hideOnSinglePage="true"
        show-quick-jumper
        :default-current="1"
        :total="total"
        @change="onChange"
      />
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />
    <Sidebar ref="sidebar" />
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar.vue";
export default {
  // 可用组件的哈希表
  components: { Sidebar },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      id: null, //父级id
      code: null, //父级code
      visible: false,
      fontText: "", // 文本
      tabMenu: [], // 一级分类
      // 二级分类
      Erlist: [],
      tabCode: null, // tabId
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      loading: false,
      selindex: 1, //选择的值

      selCode: null, //需要传接口的值
    };
  },
  // 事件处理器
  methods: {
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split(".");
      return txt;
    },
    font(e) {
      return e.replace(/\<[^>]+>/g, "");
    },
    routerPush(e) {
      if (e.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&code=" +
            e.code +
            "&name=" +
            this.$route.query.name
        );
      } else if (e.type == 2 || e.type == 3) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&name=" +
            this.$route.query.name
        );
      } else if (e.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&name=" +
            this.$route.query.name
        );
      }
    },
    // tab切换
    tabChange(e) {
      this.tabCode = e.code;
      this.selCode = e.code;
      this.selindex = null;
      this.Erlist = e.children;
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getShopList();
    },
    // 二级tab切换
    taberChange(e) {
      this.selCode = e;
      this.selindex = e;
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getShopList();
    },
    // 分页
    onChange(pageNumber) {
      this.pageNum = pageNumber;
      this.list = [];
      this.getShopList();
    },
    // 获取一级分类
    getChildType() {
      // this.$ajax({
      //   url: "/hxclass-pc/course/category",
      //   method: "get",
      //   // params: {
      //   //   pId: this.id,
      //   //   code: this.code,
      //   // },
      // }).then((res) => {
      //   if (res.code == 200 && res.success) {
      //     this.tabMenu = res.data;
      //     if (res.data.length) {
      //       this.Erlist = res.data[0].children;
      //       this.tabCode = res.data[0].code;
      //     }

      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
      if (this.$route.query.id == 1) {
        this.tabMenu = [
          {
            id: 2,
            name: "证书课程",
            code: "02",
            children: [
              {
                id: 5,
                name: "上岗课程",
                code: "0201",
                children: null,
              },
              {
                id: 6,
                name: "评估课程",
                code: "0202",
                children: null,
              },
              {
                id: 15,
                name: "CEU课程",
                code: "0203",
                children: null,
              },
            ],
          },

          {
            id: 8,
            name: "免费课程",
            code: "04",
            children: [],
          },
          {
            id: 13,
            name: "专题微课",
            code: "07",
            children: [],
          },
          {
            children: [],
            code: "10",
            id: 34,
            name: "线下培训",
          },
        ];
      } else {
        this.tabMenu = [
          {
            children: [],
            code: "06",
            id: 10,
            name: "相关教具",
          },
          {
            id: 9,
            name: "精品图书",
            code: "05",
            children: [
              {
                id: 21,
                name: "培训用书",
                code: "0502",
                children: null,
                productList: [
                  {
                    productId: 120,
                    name: "测试商品ID",
                    code: "AT0078",
                    type: "3",
                  },
                  {
                    productId: 115,
                    name: "测试运费商品",
                    code: "AT0077",
                    type: "3",
                  },
                  {
                    productId: 61,
                    name: "语言行为里程碑评估及安置计划：第2版（全2册）",
                    code: "BT0045",
                    type: "2",
                  },
                  {
                    productId: 6,
                    name: "孤独症康复教育人员上岗培训教材（第二版）",
                    code: "AT0009",
                    type: "2",
                  },
                ],
              },
              {
                id: 22,
                name: "团体标准",
                code: "0503",
                children: null,
                productList: [
                  {
                    productId: 115,
                    name: "测试运费商品",
                    code: "AT0077",
                    type: "3",
                  },
                  {
                    productId: 96,
                    name: "助听器验配服务规范",
                    code: "AT0069",
                    type: "2",
                  },
                  {
                    productId: 95,
                    name: "应用行为分析人员技能要求",
                    code: "AT0068",
                    type: "2",
                  },
                  {
                    productId: 94,
                    name: "儿童应用行为分析服务规范",
                    code: "AT0067",
                    type: "2",
                  },
                ],
              },
              {
                id: 23,
                name: "自我提升",
                code: "0504",
                children: null,
                productList: [
                  {
                    productId: 115,
                    name: "测试运费商品",
                    code: "AT0077",
                    type: "3",
                  },
                  {
                    productId: 44,
                    name: "活出生命的意义（精装新版）",
                    code: "AT0028",
                    type: "2",
                  },
                  {
                    productId: 32,
                    name: "看不见的沟通 --激发员工潜力的萨提亚教练模式",
                    code: "AT0016",
                    type: "2",
                  },
                ],
              },
              {
                id: 24,
                name: "亲子育儿",
                code: "0505",
                children: null,
                productList: [
                  {
                    productId: 115,
                    name: "测试运费商品",
                    code: "AT0077",
                    type: "3",
                  },
                  {
                    productId: 58,
                    name: "0～5岁关键心智养成",
                    code: "AT0042",
                    type: "2",
                  },
                  {
                    productId: 57,
                    name: "德德家家幼儿性教育系列",
                    code: "AT0041",
                    type: "2",
                  },
                  {
                    productId: 56,
                    name: "小学生的心思:5-12岁关键心智养成",
                    code: "AT0040",
                    type: "2",
                  },
                ],
              },
              {
                id: 26,
                name: "亲子家教",
                code: "0506",
                children: null,
                productList: [
                  {
                    productId: 115,
                    name: "测试运费商品",
                    code: "AT0077",
                    type: "3",
                  },
                  {
                    productId: 97,
                    name: "《课外阅读》杂志下半年合订",
                    code: "AT0070",
                    type: "2",
                  },
                  {
                    productId: 71,
                    name: "从0岁开始（1-4岁）",
                    code: "AT0055",
                    type: "2",
                  },
                  {
                    productId: 55,
                    name: "运动塑造孩子的大脑：0-7岁运动套餐全方案",
                    code: "AT0039",
                    type: "2",
                  },
                ],
              },
              {
                id: 27,
                name: "儿童康复",
                code: "0507",
                children: null,
                productList: [
                  {
                    productId: 115,
                    name: "测试运费商品",
                    code: "AT0077",
                    type: "3",
                  },
                  {
                    productId: 101,
                    name: "脑瘫儿童的家庭康复",
                    code: "AT0074",
                    type: "2",
                  },
                  {
                    productId: 100,
                    name: "孤独症儿童的家庭康复",
                    code: "AT0073",
                    type: "2",
                  },
                  {
                    productId: 99,
                    name: "家庭康复护理常识",
                    code: "AT0072",
                    type: "2",
                  },
                ],
              },
            ],
            productList: [],
          },
        ];
      }

      this.Erlist = this.tabMenu[0].children;
      this.tabCode = this.tabMenu[0].code;
      this.selCode = this.tabMenu[0].code;
      this.getShopList();
    },

    getShopList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/list",
        method: "get",
        params: {
          categoryCode: this.selCode, // 分类编码
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.list = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.query.id) {
      // this.id = this.$AES.decode_data(this.$route.query.id);
      this.code = this.$route.query.code;
      this.getChildType(); // 获取二级分类
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .tabs {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 40px;
    margin-top: 22px;
    background-color: rgba(21, 183, 221, 0.1);
    border-bottom: 1px solid #15b7dd;
    .tabItem {
      position: relative;
      display: inline-block;
      padding: 0 38px;
      line-height: 38px;
      font-size: 18px;
      color: #666666;
      margin-right: 20px;
      cursor: pointer;
    }
    .tabItem:last-child {
      margin: 0;
    }
    .active {
      color: #ffffff;
      background-color: #15b7dd;
    }
  }
  ::v-deep.ant-pagination {
    text-align: right;
    padding-right: 29px;
    padding-top: 45px;
  }
  .contentList {
    margin-top: 24px;
    // padding: 40px 0px;
    min-height: 300px;
    border-radius: 5px;
    background: #ffffff;
    position: relative;
    .contentItem {
      background: #ffffff;
      border-radius: 5px;
      padding: 30px 60px;
      border-bottom: 1px solid #e1e1e1;
      transition: all 0.5s;
      display: flex;
      > img {
        width: 220px;
        height: 192px;
        border-radius: 4px;
        transition: all 0.5s;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      .content {
        padding: 8px 24px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        .left {
          width: 580px;
          .name {
            cursor: pointer;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
          }
          .navs {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            height: 24px;
            overflow: hidden;
            flex-wrap: wrap;
            margin-top: 16px;
            word-wrap: break-word;
            word-break: normal;
            line-height: 21px;
            span {
              display: inline-block;
            }
            .type {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #1e97b4;
              line-height: 21px;
              margin-right: 16px;
            }
            .nav {
              font-size: 12px;
              font-family: PingFangMedium;
              color: #ec6c01;
              line-height: 18px;
              border-radius: 4px;
              border: 1px solid #ec6c01;
              padding: 2px 8px;
              font-weight: 400;
              margin-right: 8px;
            }
          }
          .teacherName {
            margin-top: 12px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #666666;
            line-height: 21px;
          }
          .slogan {
            margin-top: 26px;
            font-size: 18px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 24px;
          }
          .introduce {
            margin-top: 26px;
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            position: relative;
            // display: flex;
            // flex-wrap: wrap;
            .backGround {
              padding-right: 24px;
              background-image: url(~@/assets/image/tag.png);
              background-size: 100% 100%;
            }
            span {
              white-space: nowrap;
              height: 21px;
              padding: 0 0 0 3px;
              text-align: center;
              display: inline-block;
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 600;
              color: #ffffff;
              line-height: 24px;
              background-color: rgba(255, 120, 0, 0.8);
              > span {
                font-size: 20px;
                line-height: 21px;
                align-items: center;
              }
            }
            span:nth-child(2) {
              margin-right: 20px;
            }
            .white {
              width: 0;
              height: 0;
              border-bottom: 24px solid white;
              border-left: 24px solid transparent;
              position: absolute;
              top: 3px;
              left: 95px;
            }
          }
        }
        .right {
          width: 200px;
          .price {
            // display: flex;
            align-items: center;
            // margin-top: 19px;
            .new {
              font-size: 16px;
              font-family: PingFangMedium;
              color: #ec6c01;
              line-height: 21px;
              span {
                font-size: 28px;
              }
              .new_point {
                font-size: 16px;
              }
              .free {
                font-size: 24px;
              }
            }
            .old {
              font-size: 16px;
              font-family: PingFangMedium;
              font-weight: 400;
              text-decoration: line-through;
              color: #999999;
              line-height: 21px;
              margin-left: 9px;
            }
          }
          .button {
            width: 134px;
            height: 40px;
            background: #15b7dd;
            border-radius: 2px;
            border-radius: 40px;
            margin-top: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:hover::before {
              left: 100%;
            }
            p {
              margin-left: 5px;
            }
          }
          .button::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            background: linear-gradient(
              90deg,
              transparent,
              hsla(0, 0%, 100%, 0.5),
              transparent
            );
            transition: all 0.5s;
          }
        }
      }
    }
    .contentItem:first-child {
      .white {
        top: 2px !important;
      }
    }
    .contentItem:hover {
      position: relative;
      z-index: 2;
      border-bottom: 1px solid #ffffff;
      box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
      > img {
        transform: scale(1.05, 1.05);
      }
    }
    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .main-box .tabs {
    padding: 0 30px;
    .tabItem {
      padding: 0 25px;
    }
  }
}

.navbar {
  width: 100%;
  overflow: hidden;
  margin-top: 23px;
  ul {
    display: block;
    li {
      float: left;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-right: 24px;
      line-height: 27px;
      cursor: pointer;
    }
  }
}
.selColor {
  color: #15b7dd !important;
}
.label {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ff0000;
  padding: 0.5px 8px;
  display: inline-block;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ec6c01;
}
.button2 {
  // width: 134px;
  // height: 34px;
  // border-radius: 20px 20px 20px 20px;
  // border: 1px solid #15b7dd;
  text-align: left;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #15b7dd;
  cursor: pointer;
  line-height: 34px;
  margin-top: 92px;
  img {
    width: 16px !important;
    height: 16px !important;
    margin-left: 8px;
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 6px;
  border-color: transparent transparent #ffffff transparent;
  position: relative;
  top: 2px;
  // left: -5px;
  margin: 0 auto;
  margin-top: -10px;
}
</style>
